@tailwind base;
@tailwind components;
@tailwind utilities;

html,body,#root{
    width: 100%;
    height: 100%;
}
.swiper-slide {

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper {
    width: 100%;
    height: 100%;
  }
  .swiper-button-next:after{font-size: 35px!important;}
  .swiper-button-prev:after{font-size: 35px!important;}
  html {
    scroll-behavior: smooth;
}
  #pricing,#feautures {
    scroll-margin-top: 100px;
    scroll-behavior: smooth;
}
.collapse-arrow > .collapse-title:after{
  color:#fff;
}

@keyframes anim-pop {
  0% {
      transform: scale(var(--btn-focus-scale,.98))
  }

  40% {
      transform: scale(1.02)
  }

  to {
      transform: scale(1)
  }
}
@media (prefers-reduced-motion: no-preference) {
  .anim-pop {
      animation: anim-pop .25s ease-out;
  }
  .anim-pop2 {
    animation: anim-pop 1s ease-out;
}
}
